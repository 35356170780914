import * as React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import Layout from "../components/layout";
import styled from "styled-components";

const TequilasContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const TequilaContainer = styled.div`
  flex-basis: 30%;
  position: relative;
  margin-bottom: 30px;

  h3 {
    position: absolute;
    bottom: 0px;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    font-size: 28px;
    padding: 5px 0px;
    text-align: center;
  }

  .tequilaImage {
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: 50% 50%;
  }

  @media only screen and (max-width: 600px) {
    flex-basis: 90%;
  }
`;

const CocktailssContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const CocktailContainer = styled.div`
  flex-basis: 30%;
  position: relative;
  margin-bottom: 30px;

  .cocktailImage {
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: 50% 50%;
  }

  @media only screen and (max-width: 600px) {
    flex-basis: 90%;
  }
`;

// markup
const DrinkPage = (data) => {
  return (
    <Layout title={"Drinks"}>
      <h2>Tequilas</h2>
      <TequilasContainer>
        {data.data.allSanityDrink.nodes
          .filter((drink) => drink.drinktype.includes("tequila"))
          .map((t) => (
            <TequilaContainer>
              <h3>{t.name}</h3>
              {t.image && (
                <Image className="tequilaImage" {...t.image} alt={t.name} />
              )}
            </TequilaContainer>
          ))}
      </TequilasContainer>

      <h2>Cocktails</h2>
      <CocktailssContainer>
        {data.data.allSanityDrink.nodes
          .filter((drink) => drink.drinktype.includes("cocktail"))
          .map((special) => (
            <CocktailContainer>
              <h3>{special.name}</h3>
              <div>{special.description}</div>
              {special.image && <Image {...special.image} alt={special.name} />}
            </CocktailContainer>
          ))}
      </CocktailssContainer>
    </Layout>
  );
};

export const query = graphql`
  query DrinkQuery {
    allSanityDrink {
      nodes {
        description
        name
        drinktype
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`;

export default DrinkPage;
